import axios from "axios";

const state = {
    cars: [],
    vehicle: {},
    vehicleId: {},
    filtercars: [],
    selectedGrade: "",
    selectedGradeObj: {},
    sort_by: "lth", //for sorting low to high
};

const getters = {
    getCars: (state) => state.cars,
    getSelectedCar: (state) => state.vehicle,
    getSelectedCarVehicleId: (state) => state.vehicleId,
    // getGrade: (state) => state.selectedGrade,
};

const actions = {
    async fetchCars({ commit, }) {
        const response = await axios.get(
            `json/nissan-car-3.json`
        )

        // const response = await axios.get(
        //     `https://3f8a-2401-4900-619c-b243-2c52-1377-4b4d-3c54.in.ngrok.io/api/models/3`, { 'headers': {  "ngrok-skip-browser-warning": true } } )
        //     .then((response) => {
        //        response = response.data;
        //        console.log(response);
        //     })
        //     .catch((error) => {
        //         console.log(error)
        //     })
        commit("setCars", response.data);
    },

    // async getSelectedCar({ commit, dispatch, rootState }, id) {
    //     let axiosSource = axios.CancelToken.source();
    //     const request = { cancel: axiosSource.cancel() };
    //     request.cancel;
    //     // const localData = JSON.parse( localStorage.getItem("myCars"));

    //     const response = await axios.get(
    //         `https://bkend.aac-usedcars.com/api/v1/vehicles`
    //     );
    //     const selected = response.data.data.filter(element => {
    //         return element.id == id
    //     });
    //     commit("selectedCar", selected[0]);
    // },

    // async getSelectedCar({ commit }, cartitle) {
    //     commit("selectedCar", cartitle);
    // },
};

const mutations = {
    setCars: (state, cars) => {
        state.cars = cars;
    },
    selectedCar: (state, car) => {
        state.vehicle = car;
    },
    selectedCarVehicleId: (state, car) => {
        state.vehicleId = car;
    },
};

// then need to export
export default {
    state,
    getters,
    actions,
    mutations,
};
