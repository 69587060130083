<template>
  <div class="appWrapper">
    <div class="mainOptionWrapper" ref="mainOptionRef" id="mainOptionWrapper" v-if="exOptions">
      <div class="mainOption" v-for="(category, index) in this.selectedGradeCategories" :key="index"
        @click="getCategory(category, index)" :class="{ activeMain: index === activeOptionId }">
        <div class="option">
          <div class="labelIn">{{ category }}</div>
          <span></span>
        </div>
      </div>
    </div>
    <!-- <div
      class="sm-toggleMenu"
      @click="toggleFeatureLeft"
      :class="{ active: isActiveFeaturebox }"
      v-show="sm_toggleMenu"
      ref="sm_toggleMenu"
    >
      <span></span>
      <span></span>
      <span></span>
    </div> -->
    <div>
      <div class="landingInner" v-if="this.vehicle">
        <div class="navWrapper fade-in-top" v-if="!isLoading">
          <!-- Categories ( Exterior & Interior Area ) -->
          <div class="mainOptionWrap" ref="mainOptionRef" id="mainOptionWrapper" v-if="exOptions">
            <div class="mainOption" v-for="(category, index) in this.selectedGradeCategories" :key="index">
              <div class="option" @click="getCategory(category, index)" :class="{ activeMain: index === activeOptionId }">
                <div class="labelIn">{{ category }}</div>
                <span></span>
              </div>
            </div>
          </div>
          <!-- // Categories ( Exterior & Interior Area ) -->
        </div>
        <!-- navWrapper end -->

        <div ref="InExWrapper" class="InExWrapper">
          <div class="Ex" v-if="!interiorMaster && threeSixtyImagePath" ref="Exee"
            :class="hotspotclicked ? 'zoomed' : ''">
            <div v-if="this.threesixtyStatus">
              <vue-three-sixty ref="_360_" :key="this.dataChanged" :amount="this.imageCount"
                :imagePath="this.threeSixtyImagePath" :fileName="this.threeSixtyFilename" scrollImage
                :hotspots="this.exteriorHotspots" :setSpotCurrentImage="this.setSpotCurrentImage"
                @getHotWidget="fetchExteriorHot($event)"
                :openHotspotFormForFeatureId="this.openHotSpotFormImgFeatureId" />
            </div>
            <!-- <div class="colorName">
              <span> {{ colorName }} </span>
            </div> -->

            <div v-if="this.vehicleFoundStatus" class="exNotFound">
              Exterior image not found!
            </div>


            <div class="widgetBottom">
              <div class="select">
                <select @change="onVehicleModelChange($event)" v-model="this.selectedvehicleModel">
                  <option v-for="(item, index) in this.vehicleModelList" :key="index" :value="item.name">
                    {{ item.name }}
                  </option>
                </select>
              </div>

              <div class="colorBox">
                <div class="headBox" @click="toggleColorBox">
                  <div class="color">
                    <span>

                    </span>
                    <label>
                      {{ this.selectedColor }}
                    </label>
                  </div>
                  <span class="angle"><i> <img src="@/assets/img/angle_down_icon.png" /></i></span>
                </div>

                <div class="colorsIn" v-if="this.colorsIn">
                  <ul>
                    <li v-for="(imgColour, index) in this.imageColourList" :key="index"
                      :class="{ active: index === isActiveColor }"
                      @click="changeExteriorCarColour(imgColour.colour_code, index)">
                      <img :src="$apibaseurl + imgColour.colour.image" />
                    </li>
                  </ul>
                </div>

              </div>

              <div class="ctaWrap">
                <div class="discoverBtn">
                  <a href="https://en.nissan-dubai.com/services/book-a-test-drive.html">Book a test drive</a>
                </div>
                <div class="discoverBtn">
                  <a :href="this.discoverUrl">Discover more</a>
                </div>
              </div>
            </div>
          </div>
          <Loader v-if="this.divLoaded" />
        </div>

        <!-- interior start -->
        <div class="interiorMasterWrapper" v-if="interiorMaster">
          <div class="In" v-if="this.vehicleData.grades.length > 0 && this.dataChanged">
            <PanoramaView :vehicleModel="this.vehicleData.model" :vehicleData="this.interiorData"
              :interiorHotspots="this.interiorHotspots" :windowWidth="this.windowWidth" :key="this.dataChanged"
              :openHotspotFeatureBox="this.openHotSpotFormImgFeatureId" />
          </div>
          <div v-else class="InteriorNot">
            Interior image not found!
          </div>
        </div>
        <!-- interior end -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Logo from "../components/logoNissan.vue";
import Loader from "../components/loader.vue";
import CarouselColor from "../components/vueCarousel.vue";
import "vue-360/dist/css/style.css";
import store from '@/store';
import adobeAnalytics from "@/plugin/analytics";

export default {
  name: "LandingView",
  props: {
    action: Boolean,
  },
  components: {
    PanoramaView: () => import("../components/interiorPanorama.vue"),
    Logo,
    Loader,
    CarouselColor,
  },

  data() {
    return {
      discoverUrl: "",
      colorsIn: false,
      selectedvehicleModel: null,
      threesixtyStatus: true,
      selectedColor: null,
      vehicleFoundStatus: false,
      vehicleModelList: [],
      selectedVehicleModelName: null,
      selectedVehicleId:null,
      choosedVehicle: '',
      sidePanel: false,
      playOnce: false,
      colorName: null,
      exOptions: true,
      gradeIn: false,
      divLoaded: false,
      isLoadingFeature: true,
      isLoadingFeature2: true,
      isLoading: true,
      modelName: null,
      vehicle: {},


      // adobe
      catgoryTypo: '',
      adobeGradecode: null,
      modelCode: "",
      defColor: null,
      categoryExInt: null,

      openHotSpotFormImgFeatureId: 0,

      toggleLeftFeatureBox: true,
      isActiveFeaturebox: true,
      toggleClicked: false,
      toggleRightFeatureBox: true,
      sm_toggleMenu: true,

      imageCount: 18,
      threeSixtyImagePath: null,
      threeSixtyFilename: "car_{index}.png",

      widgetActive: false,
      isWidgetHotActive: false,

      activeOptionId: 0,
      gradeWalkId: 0,
      activeGradeId: 0,
      activeFeatureId: 0,
      activeIntId: 1,
      isActiveMain: false,
      mainOptionRef: false,
      windowWidth: null,
      mobMode: false,
      isActiveColor: null,

      exteriorHotspots: [
        {
          id: null,
          frame: null,
          heading: null,
          text: null,
          x: null,
          y: null,
          video: null,
          thumbnail: null,
        },
      ],

      interiorHotspots: [
        {
          pitch: null,
          yaw: null,
          type: "info",
          heading: null,
          text: null,
          video: null,
          thumbnail: null,
        },
      ],

      clickedHotspot: [
        {
          id: null,
          frame: null,
          heading: null,
          text: null,
          x: null,
          y: null,
          video: null,
          thumbnail: null,
        },
      ],

      hotspotclicked: false,
      filteredResultArr: [],

      exteriorFeatures: true,
      interiorFeatures: false,

      spotWrapper: false,

      interiorMaster: false,

      vehicleData: {
        grades: null,
        grade_data: {},
        model: null,
        category: null,
        categoryImageCount: null,
        interiorData: {},
      },
      dataChanged: null,
      setSpotCurrentImage: 0,
      widgetActive: false,
      hotSpotActiveId: "",
      colorsTray: true,
      imageColourList: {},

      selectedGrade: "",
      selectedGradeCategories: [],
      selectedGradeExteriorFeatures: [],
    };
  },
  mounted: function () {
    this.$nextTick(function () {
      if (this.selectedVehicleModelName) {
        setTimeout(() => {
          this.isLoading = false;
        }, 1000);

        this.isLoadingFeature2 = false;
        this.isLoadingFeature = false;
      }

      window.addEventListener("load", () => {
        this.windowWidth = window.innerWidth;
      });

      if (this.windowWidth < 991) {
        this.mobMode = true;
      }

      const targetDiv = this.$refs._360_;

      setTimeout(() => {
        if (this.imageCount > 20) this.divLoaded = true;
      }, 7000);
    });

    this.$el.addEventListener("click", this.onClick);
  },
  updated: function () { },
  created() {
    this.getVehicles();
  },
  computed: {},
  methods: {
    toggleColorBox() {
      if (this.colorsIn == true) {
        this.colorsIn = false;
      } else{
        this.colorsIn = true;
      }
    },

    getVehicles() {

      axios
        .get(this.$apibaseurl + "api/middle-east-models")
        .then((result) => {
          this.vehicleModelList = result.data.data;
          this.selectedVehicleModelName = this.vehicleModelList[0].name;
          this.selectedvehicleModel = this.selectedVehicleModelName
          //this.selectedColor = colourCode
          this.getVehicleModelData();
        });
    },

    onVehicleModelChange(event) {
      this.selectedVehicleModelName = event.target.value;
      this.getVehicleModelData();
    },

    toggleFeatureLeft() {
      // this.toggleLeftFeatureBox = !this.toggleLeftFeatureBox;
      // this.toggleRightFeatureBox = !this.toggleRightFeatureBox;
      // this.isActiveFeaturebox = !this.isActiveFeaturebox;
      this.$refs.sidePanel.classList.add("show");
    },

    closeSidePanel() {
      this.$refs.sidePanel.classList.remove("show");
      this.$refs.sidePanel.classList.add("hidePanel");
    },

    getIntRoute(cat, i) {
      if (i == 0) this.activeOptionId = i;

      if (cat == "Exterior") {
        this.interiorMaster = false;
        this.colorsTray = true;
        this.exOptions = true;
        this.sm_toggleMenu = true;
      }
    },
    handleFocusOut() {
      // this.$refs.exToggleMenu.checked = false;
      // this.$refs.featuresOptionnRef.classList.remove("openPanel");
      // this.widgetActive = false;
      // this.$refs.InExWrapper.classList.remove("widgetHotActive");
      // this.$refs.Exee.classList.remove("zoomed");
    },
    onClick() {
      if ((this.divLoaded = true)) {
        this.divLoaded = false;
      }
    },
    openFeatures() {
      if (this.$refs.featuresOptionnRef.classList.contains("openPanel")) {
        this.$refs.featuresOptionnRef.classList.remove("openPanel");
      } else {
        this.$refs.featuresOptionnRef.classList.add("openPanel");
        //this.$refs.featuresOptionnRef.classList.remove("hidePanel");
      }
    },
    playVideoClick() {
      const video = document.getElementById("vehicleVideo");
      if (video.paused || video.ended) {
        video.play();
      } else {
        video.pause();
      }

      const circlePlayButton = document.getElementById("circle-play-b");

      video.addEventListener("playing", function () {
        circlePlayButton.style.opacity = 0;
      });
      video.addEventListener("pause", function () {
        circlePlayButton.style.opacity = 1;
      });
    },
    hideSpotWrapper() {
      // this.hotSpotActiveId = '';
      this.widgetActive = false;
      this.$refs.Exee.classList.remove("zoomed");
      this.$refs.InExWrapper.classList.remove("widgetHotActive");
    },
    changeExteriorCarColour(colourCode, index) {

      // console.log(colourCode,'onExtChange - Color code value')
      this.isActiveColor = index;

      this.selectedColor = colourCode.replace(/-/g, " ");

      this.vehicleData.categoryImageCount = this.selectedGrade.categories[0].category_images.filter((element) => {
        return element.colour_code == colourCode;
      }).length;

      var vehicleImagePath = this.selectedGrade.categories[0].category_images.filter((element) => {
        return element.colour_code == colourCode;
      })[0].image_path;

      this.threeSixtyImagePath = this.$apibaseurl + vehicleImagePath;
      this.imageCount = this.vehicleData.categoryImageCount;

      this.dataChanged = Math.floor(Math.random() * 10) + 1;

      this.colorName = colourCode.replace(/-/g, " ");

      let event_happened = this.colorName;
      let vehicle_name = this.selectedvehicleModel;//this.modelName;
      let vehicleid = this.selectedVehicleId;
      let vehicle_catgory = this.categoryExInt;
      let modelCode = this.modelCode;

      let event_126 = "";
      let events = 'event26';

      let all = vehicle_name + "|" + vehicle_catgory + "|" + this.adobeGradecode + "|"  + event_happened

      //console.log(vehicle_name,"vehicle_name");

      adobeAnalytics(
        "R",
        vehicle_name,
        "",
        vehicle_name,
        vehicleid,
        "EN",
        event_happened,
        "",
        "",
        "",
        event_126,
        events,
        "",
        all
      );
    },
    async getVehicleModelData() {

      const url = 'https://en.nissan-dubai.com/vehicles/new'
      this.discoverUrl = `${url}/${this.selectedVehicleModelName.toLowerCase()}`
      this.selectedvehicleModel = this.selectedVehicleModelName;
      this.selectedVehicleId = this.selectedVehicleModelName === 'Altima' ? 'L34' : this.selectedVehicleModelName === 'Sunny' ? 'N18' : null;
      let event_happened = this.selectedvehicleModel;
      let vehicle_name = this.selectedvehicleModel;
      let vehicleid = this.selectedVehicleId;
            
      await axios
        .get(this.$apibaseurl + "api/model/" + this.selectedVehicleModelName)
        .then((result) => {
          this.vehicle = result.data;
          this.dataChanged = Math.floor(Math.random() * 10) + 1;
          this.vehicleData.grades = result.data.grades;

          if (this.vehicleData.grades[0] !== undefined && this.dataChanged) {
            var firstLoadedColor = this.vehicleData.grades[0].categories[0].category_image_colours[0].colour_code;
            this.getGradeData(this.vehicleData.grades[0].grade_code, 0, firstLoadedColor, 0);
            this.selectedColor = firstLoadedColor.replace(/-/g, " ");
            this.threesixtyStatus = true;
            this.vehicleFoundStatus = false;

          } else {
            this.vehicleFoundStatus = true;
            this.threesixtyStatus = false;
          }

          this.isActiveColor = '';

        });

        
        let event_126 = "";
        let events = 'event26';

        let all = vehicle_name + "|" + this.adobeGradecode

        adobeAnalytics(
        "R",
        vehicle_name,
        "",
        vehicle_name,
        vehicleid,
        "EN",
        event_happened,
        "",
        "",
        "",
        event_126,
        events,
        "",
        all
      );
      store.commit('selectedCar', this.selectedvehicleModel)
      store.commit('selectedCarVehicleId', this.selectedVehicleId)

    },
    getGradeData(gradeCode, id, color, extOrInt) {

      // console.log(gradeCode,"grade Code value 2");
      // console.log(color,"grade color value");

      this.adobeGradecode = gradeCode;

      this.gradeWalkId = id;
      this.openHotSpotFormImgFeatureId = 0;

      // const defColor = this.vehicleData.grades[0]
      // console.log(defColor,"defColor");

      this.colorName = color;

      /**
       * When Grade is changing, that time, you need to change these below datas:
       * change Categories.
       * change Images
       * change Colours
       * change Features
       */

      //removing active color class when change grade

      //this.$refs.CarouselColor.setValue(0);

      //this.selectedVehicleModelName = this.vehicleModelList[0].name;

      const selectedGrade = this.vehicleData.grades.filter((value) => {
        return value.grade_code == gradeCode;
      });
      //console.log(selectedGrade, '1 selected Grade');
      this.selectedGrade = selectedGrade[0];

      this.categoryExInt = this.selectedGrade.categories[extOrInt].name;

      // Lets change the Categories
      this.selectedGradeCategories = [];
      this.selectedGrade.categories.map((cateItem) => {
        // console.log(cateItem.name, 'items');
        this.selectedGradeCategories.push(cateItem.name);
      });
      // -- Lets change the Categories

      this.imageColourList = this.selectedGrade.categories[0].category_image_colours;
      // console.log(this.imageColourList, 'img colour lists')

      this.vehicleData.categoryImageCount = this.selectedGrade.categories[0].category_images.filter((element) => {
        return element.colour_code == this.imageColourList[0].colour_code;
      }).length;
      // console.log(this.vehicleData.categoryImageCount, 'img count');

      var vehicleImagePath = this.selectedGrade.categories[0].category_images.filter((element) => {
        return element.colour_code == this.imageColourList[0].colour_code;
      })[0].image_path;

      this.threeSixtyImagePath = this.$apibaseurl + vehicleImagePath;
      //console.log(this.threeSixtyImagePath, 'image path');
      this.imageCount = this.vehicleData.categoryImageCount;

      // var exteriorCategoryFeatures = this.selectedGrade.categories[0].category_image_features;

      // this.selectedGradeExteriorFeatures = exteriorCategoryFeatures;
      // // console.log(exteriorCategoryFeatures, 'exteriorCategoryFeatures')

      this.dataChanged = Math.floor(Math.random() * 10) + 1;

      // this.exteriorHotspots = [];
      // exteriorCategoryFeatures.map((item) => {
      //   this.exteriorHotspots.push({
      //     id: item.id,
      //     frame: item.category_image_no,
      //     heading: item.heading,
      //     text: item.description,
      //     x: item.spot_x,
      //     y: item.spot_y,
      //     video: item.video,
      //     thumbnail: item.video_poster,
      //   });
      // });

      // // -- Exterior Data

      // // Start - Interior Feature
      this.interiorData = this.selectedGrade.categories[1];

      var interiorCategoryFeatures = this.selectedGrade.categories[1].category_image_features;

      // this.interiorHotspots = [];
      // interiorCategoryFeatures.map((item) => {
      //   this.interiorHotspots.push({
      //     id: item.id,
      //     pitch: item.spot_x,
      //     yaw: item.spot_y,
      //     type: "info",
      //     text: item.heading,
      //     heading: item.heading,
      //     description: item.description,
      //     video: this.$apibaseurl + item.video,
      //     thumbnail: this.$apibaseurl + item.video_poster,
      //     clickHandlerFunc: this.hotSpotModalFunc,
      //     clickHandlerArgs: item.id,
      //   });
      // });
      // // End - Interior Feature

      // console.log(this.interiorHotspots, "Interior Hotspot");
    },
    hotSpotModalFunc(data, id) {
      this.$emit("hotspotClickOpenModal", id);
    },
    getCategory(type, id) {
      this.activeOptionId = id;

      if (type == "Interior") {
        //   this.$refs.extmenuToggle.classList.add("hideBurger");
        //   this.colorsTray = false;
        //   this.interiorMaster = true;
        //   this.interiorFeatures = true;
        //   this.exteriorFeatures = false;

        //   if ((this.widgetActive = true)) {
        //     this.widgetActive = !this.widgetActive;
        //   }
        // } else {
        //   this.$refs.exToggleMenu.checked = false;

        //   let element = document.getElementById("featuresOption");

        //   setTimeout(() => {
        //     if (element.classList.contains("openPanel")) {
        //       this.$refs.featuresOptionnRef.classList.remove("openPanel");
        //     }
        //   }, 0);

        //   this.$refs.extmenuToggle.classList.remove("hideBurger");
        //   this.colorsTray = true;
        //   this.interiorMaster = false;
        //   this.interiorFeatures = false;
        //   this.exteriorFeatures = true;
        // }

        if (this.dataChanged) {
          this.interiorMaster = true;
        }
        //this.exOptions = false;

      } else {
        this.interiorMaster = false;
      }

      let event_happened = this.colorName;
      let vehicle_name = this.selectedvehicleModel;
      let vehicleid = this.selectedVehicleId;
      
      let event_126 = "";
      let events = 'event26';

      let all = vehicle_name + "|" + type + "|" + this.adobeGradecode

      adobeAnalytics(
        "R",
        vehicle_name,
        "",
        vehicle_name,
        vehicleid,
        "EN",
        event_happened,
        "",
        "",
        "",
        event_126,
        events,
        "",
        all
      );

    },
    getFeatures(featureData, id) {
      this.playOnce = false;
      this.activeOptionId = id;

      this.activeFeatureId = id;
      this.setSpotCurrentImage = parseInt(featureData.category_image_no);

      // console.log('ex hotspot feature id landing',featureData.id);
      this.openHotSpotFormImgFeatureId = parseInt(featureData.id);

      this.dataChanged = featureData.category_image_no;

      this.hotSpotActiveId = featureData.id;

      this.isWidgetHotActive = false;

      this.$refs.InExWrapper.classList.remove("widgetHotActive");

      // this.widgetActive = true
      if ((this.widgetActive = true)) {
        this.widgetActive = !this.widgetActive;
      }
      this.hotspotclicked = false;

      // if (
      //   featureData &&
      //   this.$refs.featuresOptionnRef.classList.contains("openPanel")
      // ) {
      //   this.$refs.exToggleMenu.checked = false;
      //   this.$refs.featuresOptionnRef.classList.remove("openPanel");
      // }
    },
    showWidget() {
      this.widgetActive = true;
    },
    hideWidget() {
      this.widgetActive = false;
    },
    fetchExteriorHot(id) {
      this.$refs.InExWrapper.classList.add("widgetHotActive");

      // this.hotSpotActiveId = id;
      this.clickedHotspot = this.exteriorHotspots.filter((e) => {
        e.id == id;

        return this.clickedHotspot;
      });
      this.hotspotclicked = true;

      const checkId = id;

      // console.log(this.clickedHotspot,"this.clickedHotspot");

      const filteredResult = this.clickedHotspot.find((e) => e.id == checkId);

      this.filteredResultArr = [];

      this.filteredResultArr.push(filteredResult);

      //console.log(this.filteredResultArr,"filteredResult");

      this.widgetActive = true;

      if ((this.widgetActive = true)) {
        this.$refs.Exee.classList.add("zoomed");
        this.isWidgetHotActive = true;

        if (this.windowWidth < 667) {
          setTimeout(() => {
            // this.$refs.mainOptionRef.classList.add("z-index_0");
            // this.$refs.featuresOptionnRef.classList.add("z-index_0");
            this.$refs.spotWrapper.classList.add("mobileSpot");
            this.$refs.sidePanel.classList.remove("show");
          }, 0);
        }
      }
      // else{
      //           this.$refs.mainOptionRef.classList.remove("z-index_0");
      //           this.$refs.featuresOptionnRef.classList.remove("z-index_0");
      //         }
    },

    CloseportraitPopup() {
      this.mobMode = false;
    },
  },

  watch: {},
};
</script>

<style>
.btnModal {
  font-size: 20px;
  padding: 11px;
  color: #fff;
  z-index: 222;
  position: absolute;
  top: 5px;
  right: 11px;
}

.area404 {
  text-align: center;
  margin-top: 15%;
}

.area404 h1 {
  font-size: 70px;
  color: #fff;
}

.area404 .title {
  font-size: 26px;
  color: #fff;
  font-weight: 400;
  margin-top: 26px;
}

.d-none {
  display: none;
}

.tooltip {
  position: absolute;
}

.v360-viewport {
  background-color: transparent;
  position: relative;
}

.v360-image-container {
  height: auto;
  /* position: absolute; */
  top: auto;
  /* bottom: 0em; */
  width: 100%;
  /* max-width: 75%; */
  max-width: 61%;
  cursor: grabbing;
  position: relative;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

#v360-menu-btns {
  display: none;
}

#v360-menu-btns {
  display: none;
}

.feature-right-box {
  right: 31px;
}

/* .closeIcon {
  position: absolute;
  right: 5%;
  top: 0;
  color: #fff;
  font-size: 21px;
  cursor: pointer;
} */
.hideBurger {
  display: none !important;
}

@media (max-width: 667px) {
  .closeIcon {
    font-size: 25px;
  }
}

.widgetHotActive .spotWrapper .descWrap {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.widgetHotActive .spotWrapper .descWrap .desc {
  margin-top: 0.55em;
}

@media (max-height: 768px) {
  .panIcon {
    /* height: 33%; */
    height: 41%;
  }
}
</style>
