import Vue from "vue";
import Vuex from "vuex";
import Cars from "./modules/cars";

// Load Vuex
Vue.use(Vuex);

// Create Store

export default new Vuex.Store({
  modules: {
    Cars,
  },
});
